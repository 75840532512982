import { Component, OnInit } from '@angular/core';
import { DisciplineService } from 'src/app/core/http/discipline.service';
import { AssetService } from 'src/app/core/http/asset.service';
import { CopService } from 'src/app/core/http/cop.service';
import { RegionService } from 'src/app/core/http/region.service';
import { DepartmentService } from 'src/app/core/http/department.service';
import { RelatedProcessService } from 'src/app/core/http/related-process.service';
import { TeamService } from 'src/app/core/http/team.service';


@Component({
  selector: 'app-admin-landing',
  templateUrl: './admin-landing.component.html',
  styleUrl: './admin-landing.component.scss'
})
export class AdminLandingComponent implements OnInit {
  

  pageTitle = "Admin Portal";

  approvalTriggerSelected: string = '';

  showTable: boolean = false;

  test: any;

  approvalTriggerValues: any;

  approvalTriggers: string[] = ['Discipline', 'Asset', 'Team', 'CoP', 'Department', 'Region', 'Related Process']

    constructor(
      private disciplineService: DisciplineService,
      private assetService: AssetService,
      private copService: CopService,
      private regionService: RegionService,
      private departmentService: DepartmentService,
      private relatedProcessService: RelatedProcessService,
      private teamService: TeamService
    ){}
  

  ngOnInit(): void {
  }

  approvalTriggerSelectedChange(event: any){
    this.showTable = false;
    switch(event){
      case "Discipline":
        this.getAllDisciplines();
        this.showTable = true;
        break;
      case "Asset":
        this.getAllAssets();
        this.showTable = true;
        break;
      case "CoP":
        this.getAllCops();
        this.showTable = true;
        break;
      case "Region":
        this.getAllRegions();
        this.showTable = true;
        break;
      case "Team":
        this.getAllTeams();
        this.showTable = true;
        break;
      case "Department":
        this.getAllDepartments();
        this.showTable = true;
        break;
      case "Related Process":
        this.getAllRelatedProcesses();
        this.showTable = true;
        break;
      default:
        this.showTable = false;
        break;
    }
  }

  getAllDisciplines(){
    this.disciplineService.getAllDisciplines().subscribe(res => {
      this.approvalTriggerValues = res;
    })
  }

  getAllAssets(){
    this.assetService.getAllAssets().subscribe(res => {
      this.approvalTriggerValues = res;
    })

  }

  getAllCops(){
    this.copService.getAllCops().subscribe(res => {
      this.approvalTriggerValues = res;
    })
  }

  getAllRegions() {
    this.regionService.getAllRegions().subscribe(res => {
      this.approvalTriggerValues = res;
    })

  }

  getAllTeams() {
    this.teamService.getAllTeams().subscribe(res => {
      this.approvalTriggerValues = res;
    })

  }

  getAllDepartments() {
    this.departmentService.getAllDepartments().subscribe(res => {
      this.approvalTriggerValues = res;
    })

  }

  getAllRelatedProcesses() {
    this.relatedProcessService.getAllRelatedProcesses().subscribe(res => {
      this.approvalTriggerValues = res;
    })

  }
}

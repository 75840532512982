import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LessonQualityResponse } from "src/app/shared/models/lesson-quality-response";

@Injectable({
  providedIn: "root",
})
export class LessonQualityService {
  constructor(private http: HttpClient) { }

  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}LessonQuality`;


  
  getLessonQualityRating(body): Observable<any> {
    const endPoint = `${this.apiRoot}/rateLesson`;
    return this.http.post<LessonQualityResponse>(endPoint, body);
  }
}
import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { Asset } from "src/app/shared/models/asset";


@Injectable({
  providedIn: "root",
})
export class AssetService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}Asset`;

    public createAsset(code: string, description: string): Observable<Asset> {
      return this.http.post<Asset>(`${this.apiRoot}/createDiscipline/code=${code}/description=${description}`, null);
    }

    public getAsset(code: string): Observable<Asset> {
      return this.http.get<Asset>(`${this.apiRoot}/getAsset/${code}`);
    }

    public getAllAssets(): Observable<Asset[]> {
      return this.http.get<Asset[]>(`${this.apiRoot}/getAllAssets`);
    }

    public deleteAsset(code: string): Observable<Asset> {
      return this.http.delete<Asset>(`${this.apiRoot}/deleteAsset/${code}`);
    }





}
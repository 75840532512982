import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lesson-quality-rating',
  templateUrl: './lesson-quality-rating.component.html',
  styleUrl: './lesson-quality-rating.component.scss'
})
export class LessonQualityRatingComponent implements OnInit {

  rating: number;
  reasoning: string = '';

  constructor(
    public dialogRef: MatDialogRef<LessonQualityRatingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) {}


  ngOnInit(): void {
    this.rating = this.data.rating;
    this.reasoning = this.data.reasoning;
  }

  cancel() {
    this.dialogRef.close({update: false, value: null});
  }

  submit() {
    this.dialogRef.close({ update: true, rating: this.rating, reasoning: this.reasoning });
  }

}

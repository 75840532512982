import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import {RelatedProcess } from "src/app/shared/models/related-process";

@Injectable({
  providedIn: "root",
})
export class RelatedProcessService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}RelatedProcess`;

    public createRelatedProcess(code: string, description: string): Observable<RelatedProcess> {
      return this.http.post<RelatedProcess>(`${this.apiRoot}/createRelatedProcess/code=${code}/description=${description}`, null);
    }

    public getRelatedProcess(code: string): Observable<RelatedProcess> {
      return this.http.get<RelatedProcess>(`${this.apiRoot}/getRelatedProcess/${code}`);
    }

    public getAllRelatedProcesses(): Observable<RelatedProcess[]> {
      return this.http.get<RelatedProcess[]>(`${this.apiRoot}/getAllRelatedProcesses`);
    }

    public deleteRelatedProcess(code: string): Observable<RelatedProcess> {
      return this.http.delete<RelatedProcess>(`${this.apiRoot}/deleteRelatedProcess/${code}`);
    }



}
import { Component, OnInit } from "@angular/core";
import { SqlServerService } from "src/app/core/http/sql-server.service";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import { LessonSearchService } from "src/app/core/services/lesson-services/lesson-search.service";
import { appInfo } from "src/environments/environment";
import { SharedService } from "../../shared.service";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { User } from "src/app/shared/models";

@Component({
  selector: "app-menubar",
  templateUrl: "./menubar.component.html",
  styleUrls: ["./menubar.component.scss"],
})
export class MenubarComponent implements OnInit {
  public feedbackUrl = appInfo.feedbackUrl;

  userProfile: User;

  selectedTab: any = "tab1";

  showReportedLessonsTab: boolean;
  showCoordinatorTab: boolean;
  showAdminPortalTab: boolean;

  constructor(
    public shared: SharedService,
    public singleLessonService: CreateEditSingleLessonService,
    private lessonSearchService: LessonSearchService,
    private commonService: CommonService,
    private sqlServerService: SqlServerService
  ) {}

  ngOnInit(): void {
    this.getFlawedLessonsCount();
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.getCoordinatorStatus();
    this.getAdminStatus();
  }

  getCoordinatorStatus() {
    this.showCoordinatorTab = this.userProfile.roleClaims.some(ele => ele.includes('MGR'))
  }

  getAdminStatus() {
    this.showAdminPortalTab = this.userProfile.roleClaims.some(ele => ele.includes('ADMIN'))
  }


  getFlawedLessonsCount() {
    this.sqlServerService.getFlawedLessonsCount().subscribe((result) => {
      if (result >= 0) {
        this.showReportedLessonsTab = true;
      } else {
        this.showReportedLessonsTab = false;
      }
    });
  }

  onClick(check) {
    if (check == 4 && this.shared.tab == "tab3")
    {
      this.lessonSearchService.searchTrigger.next({
        scope: "AssignedLessons",
        searchText: "",
      });
      this.selectedTab = 'tab3';
    }
    else{
      this.shared.onClick(check);
      this.selectedTab = this.shared.tab;
    }
  }
}

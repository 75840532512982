<div class="row" id="myTopnav">
  <a
    (click)="shared.onClick(1)"
    routerLink="/home"
    class="active navbar-ellipsis"
    id="dash"
    >home</a
  >
  <a
    (click)="shared.onClick(10)"
    routerLink="/selectLessonWorkflowApproval"
    class="navbar-ellipsis"
    >select lesson workflow</a
    >
  <a
    id="lessonslearned"
    (click)="shared.onClick(3)"
    routerLink="/lessons"
    class="navbar-ellipsis"
    >view lessons</a
  >
  <a
    (click)="onClick(4)"
    routerLink="/lessons"
    class="navbar-ellipsis"
    >your actions</a
  >
  <a
    (click)="shared.onClick(5)"
    routerLink="/report"
    class="navbar-ellipsis"
    >metrics</a
  >
  <a
    (click)="onClick(7)"
    *ngIf="showReportedLessonsTab"
    routerLink="/reportedLessons"
    class="navbar-ellipsis"
    >reported lessons</a
  >
  <a
    (click)="shared.onClick(8)"
    routerLink="/projectLookbackView"
    class="navbar-ellipsis"
    >lookback dashboard</a
  >
  <a *ngIf="showCoordinatorTab"
  (click)="shared.onClick(9)"
  routerLink="/coordinatorDashboard"
  class="navbar-ellipsis"
  >bu manager dashboard</a
  >
  <a *ngIf="showAdminPortalTab"
  (click)="shared.onClick(10)"
  routerLink="/adminPortal"
  class="navbar-ellipsis"
  >admin portal</a
  >
</div>

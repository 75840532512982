import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from "src/app/shared/models";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage } from "@microsoft/microsoft-graph-types";
import { EventType } from "@angular/router";
import { Team } from "src/app/shared/models/team";

@Injectable({
  providedIn: "root",
})
export class TeamService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}Team`;


    public createTeam(code: string, description: string): Observable<Team> {
      return this.http.post<Team>(`${this.apiRoot}/createTeam/code=${code}/description=${description}`, null);
    }

    public getTeam(code: string): Observable<Team> {
      return this.http.get<Team>(`${this.apiRoot}/getTeam/${code}`);
    }

    public getAllTeams(): Observable<Team[]> {
      return this.http.get<Team[]>(`${this.apiRoot}/getAllTeams`);
    }

    public deleteTeam(code: string): Observable<Team> {
      return this.http.delete<Team>(`${this.apiRoot}/deleteTeam/${code}`);
    }


}
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../../../../shared/shared.module";
import { CoreModule } from "../../../../core/core.module";
import { CreateEditSharedModule } from "src/app/shared/create-edit-shared.module";
import { TextInputHighlightModule } from "angular-text-input-highlight";
import { AdminLandingComponent } from "./admin-landing/admin-landing.component";
import { ApprovalTriggerTableComponent } from "./approval-trigger-table/approval-trigger-table.component";

@NgModule({
  imports: [
    SharedModule,
    CoreModule,
    //TextInputHighlightModule,
    RouterModule.forChild([
      { path: "", component: AdminLandingComponent },
    ]),
    CreateEditSharedModule,
    
  ],
  declarations: [
    AdminLandingComponent,
    ApprovalTriggerTableComponent
  ],
})
export class AdminPortalModule {}

<app-page-header [pageTitle]="singleLessonService.pageTitle"> </app-page-header>
<app-workflow-status-progress-bar [statusTitle]="singleLessonService.statusTitle" [statuses]="singleLessonService.lessonWorkflowStatuses" [currentStatus]="singleLessonService.lesson.status" *ngIf="singleLessonService.showProgressBar && show"></app-workflow-status-progress-bar>
<div class="container-fluid module">
  <div class="row loader" *ngIf="!singleLessonService.show">
    <div class="col col-12">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <form
    [formGroup]="singleLessonService.lessonFormGroup"
    *ngIf="
      singleLessonService.lessonFormGroup && singleLessonService.showLesson
    "
  >
    <div *ngIf="singleLessonService.show">
      <div class="row">
        <div class="col col-12">
          <div class="col-12 page-header-text paddingleft30">
            <div class="left-align">
              <div class="pb-1 backText">
                <span (click)="singleLessonService.NavigateBackToHomePage()"
                  >&#60; back</span
                >
              </div>
            </div>
            <div
              class="right-align"
              *ngIf="singleLessonService.lesson.status == 'publish'"
            >
              <span style="outline: 1px solid #0066b2">
                <button class="btn btn-primary">
                  <a
                    class="preRecordinglink"
                    href="{{ singleLessonService.getRequestmoreUser() }}"
                  >
                    request more info
                    <img
                      class="icon"
                      src="assets/icons/information.svg"
                      alt=""
                    />
                  </a>
                </button>
              </span>
              <span
                style="outline: 1px solid #0066b2; margin-left: 5px"
                *ngIf="!singleLessonService.lesson.isArchived"
              >
                <button class="btn btn-primary reportLessonButton" (click)="reportLesson()">
                  report lesson
                  <img
                    class="icon"
                    src="assets/icons/report_lesson.svg"
                    alt=""
                  />
                </button>
              </span>
              <span
                style="outline: 1px solid #0066b2; margin-left: 5px"
                *ngIf="!singleLessonService.lesson.isArchived"
              >
                <button
                  class="btn reportLessonButton"
                  (click)="singleLessonService.downloadPdf()"
                >
                  download
                </button>
              </span>
              <span
                style="outline: 1px solid #0066b2; margin-left: 5px"
                *ngIf="!singleLessonService.lesson.isArchived"
              >
                <button class="btn btn-primary">
                  <a
                    class="preRecordinglink"
                    href="{{ singleLessonService.shareLesson() }}"
                  >
                    share lesson
                    <img
                      class="icon"
                      src="assets/icons/share_lesson.svg"
                      alt=""
                    />
                  </a>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div class="col col-12">
          <div class="fields-border">
            <div class="row paddinglefttopbottom_20">
              <div class="col-9 left-align">
                <b *ngIf="singleLessonService.lesson.isLessonEditable">
                  please complete all required fields</b
                >
              </div>
              <!-- <div
                class="col-3 right-align votingButtons"
                *ngIf="singleLessonService.lesson.status == 'publish'"
              >
                <span class="rateLesson">was this lesson helpful?</span>
                <span class="votingNumber">{{
                  lessonVotingService.voteCountLikes
                }}</span>
                <ng-container
                  *ngIf="
                    lessonVotingService.disableLike == false &&
                    lessonVotingService.disableDislike == false
                  "
                >
                  <button
                    class="likeDislikeBtn likeDislikeIcon material-symbols-outlined"
                    (click)="
                      lessonVotingService.createLessonVote(
                        'Likes',
                        singleLessonService.lesson.id
                      )
                    "
                  >
                    thumb_up
                  </button>
                  &nbsp; &nbsp;
                  

                  <button
                    class="likeDislikeBtn likeDislikeIcon material-symbols-outlined"
                    (click)="
                      lessonVotingService.createLessonVote(
                        'Dislikes',
                        singleLessonService.lesson.id
                      )
                    "
                  >
                    thumb_down
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="
                    lessonVotingService.disableLike == true &&
                    lessonVotingService.disableDislike == false
                  "
                >
                  <button
                    class="likeDislikeBtn likeDislikeIcon material-icons"
                    (click)="
                      lessonVotingService.createLessonVote(
                        'Likes',
                        singleLessonService.lesson.id
                      )
                    "
                  >
                    thumb_up
                  </button>
                  &nbsp; &nbsp;

                  <button
                    class="likeDislikeBtn likeDislikeIcon material-symbols-outlined"
                    (click)="
                      lessonVotingService.createLessonVote(
                        'Dislikes',
                        singleLessonService.lesson.id
                      )
                    "
                  >
                    thumb_down
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="
                    lessonVotingService.disableLike == false &&
                    lessonVotingService.disableDislike == true
                  "
                >
                  <button
                    class="likeDislikeBtn likeDislikeIcon material-symbols-outlined"
                    (click)="
                      lessonVotingService.createLessonVote(
                        'Likes',
                        singleLessonService.lesson.id
                      )
                    "
                  >
                    thumb_up
                  </button>
                  &nbsp; &nbsp;

                  <button
                    class="likeDislikeBtn likeDislikeIcon material-icons"
                    (click)="
                      lessonVotingService.createLessonVote(
                        'Dislikes',
                        singleLessonService.lesson.id
                      )
                    "
                  >
                    thumb_down
                  </button>
                </ng-container>
                <span class="votingNumber">{{
                  lessonVotingService.voteCountDislikes
                }}</span>
              </div> -->



              
              <!-- <div
                *ngIf="
                  singleLessonService.lesson.status == 'draft' &&
                  singleLessonService.lesson.id != '' &&
                  singleLessonService.lesson.id != null &&
                  singleLessonService.lesson.id != undefined
                "
                class="col-2 right-align deleteText"
              >
              <span (click)="singleLessonService.deleteLessonDialogue()">
                delete lesson
              </span>
            
              </div> -->
            </div>
            <div class="paddingleftrightbottom_20">
              <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel
                  *ngFor="
                    let itemgroup of singleLessonService.commonService
                      .visibleFieldItems;
                    let i = index;
                    let isFirst = first
                  "
                  [expanded]="isFirst"
                >
                  <mat-expansion-panel-header>
                    <div
                      class="row"
                      style="
                        padding: 0;
                        margin: 0;
                        display: flex;
                        height: 100%;
                        width: 95%;
                      "
                    >
                      <div
                        style="padding: 0; margin: 0"
                        class="color-label col col-2 left-align paddingmarginTopBottom0 paddingmarginleftright0"
                      >
                        <b
                          >{{
                            singleLessonService.commonService.getGroupDescription(
                              itemgroup.key
                            ) 
                          }}
                        </b>
                        <br />
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div style="padding: 0; margin: 0" class="row"></div>
                  <div class="row">
                    <div
                      class="col-4 paddingtop10 lessonFieldContents"
                      *ngFor="let item of itemgroup.value; let j = index"
                    >
                      <app-dynamic-component
                        #p{{i}}{{j}}
                        fieldId="{{ item.key }}"
                        [ifTooltip]="true"
                        [controlType]="item.value.feMetadata.controlType"
                        [isVisible]="item.value.feMetadata.isVisible"
                        [labelValue]="item.value.feMetadata.formLabel"
                        [showlabel]="true"
                        [className]="
                          singleLessonService.commonService.isFieldRequired(
                            singleLessonService.lesson.status,
                            item.key
                          )
                        "
                        [toolTipInstruction]="
                          singleLessonService.commonService.getLessonFieldValueInstruction(
                            item.key
                          )
                        "
                        [fieldDictatingForCrossReferenceConfig]="
                          item.value.feMetadata
                            .fieldDictatingForCrossReferenceConfig
                        "
                        [crossReferenceConfigEnum]="
                          item.value.feMetadata.crossReferenceConfigEnum
                        "
                        [isFromCreateNewLesson]="true"
                        [fieldValue]="
                          singleLessonService.getLessonFieldValue(item.key)
                        "
                        [columsWidth]="6"
                        [fieldList]="
                          item.key == 'transitionName'
                            ? singleLessonService.evalService.lessonNextSteps
                            : singleLessonService.commonService.getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
                                itemgroup.key,
                                item.key,
                                singleLessonService.lesson.lessonWorkflowType
                              )
                        "
                        [isEditable]="
                          singleLessonService.commonService.isFieldEditable(
                            singleLessonService.lesson.isLessonEditable,
                            singleLessonService.lesson.status,
                            item.key
                          )
                        "
                        [extension]="singleLessonService.extensionType"
                        [form]="singleLessonService.lessonFormGroup"
                        [previousLesson]="singleLessonService.lesson"
                        [userType]="item.value.feMetadata.dataType"
                        [referenceConfigGraph]="
                          item.value.feMetadata.referenceConfigGraph
                        "
                        [groupName]="itemgroup.key"
                        [tags]="getTags(item)"
                        (onLessonChange)="
                          singleLessonService.updateLesson($event)
                        "
                        (onValueChange)="
                          singleLessonService.onValueChange(
                            $event,
                            item.key,
                            item.value.feMetadata.referenceConfigGraph
                          )
                        "
                      >
                      </app-dynamic-component>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>

          <div
            class="col-12 right-align"
            *ngIf="singleLessonService.lesson.isLessonEditable"
          >
            <div class="right-align right-controls">
              <span
                class="color-label status"
                id="saveButton"
                (click)="saveOrSubmitLesson(false)"
              >
                Save as
                {{
                  singleLessonService.lesson.status
                    | ConvertCodeToDescription
                      : singleLessonService.commonService.references.Status
                }}
              </span>
              <button
              type="button"
              class="btn btn-outline-primary"
              (click)="rateLesson()"
              id="lessonQualityButton"
            >
              rate lesson quality
            </button>
              <button
              class="btn btn-primary"
              *ngIf="showValidateButton"
              (click)="showNaughtyWords = true"
              mat-button
              id="validateButton"
            >
              validate lesson
            </button>
            <button
            class="btn btn-primary"
            (click)="addLessonToProjectLookback()"
            id="plbButton"
          >
            + project lookback
          </button>
            
              <button
                type="button"
                class="btn btn-outline-primary"
                (click)="singleLessonService.cancelLesson()"
                id="cancelButton"
              >
                cancel
              </button>
              <button
              class="btn btn-primary"
              mat-button
              [matMenuTriggerFor]="backMenu"
              id="backButton"
              *ngIf="singleLessonService.evalService.lessonPriorSteps.length > 0"
            >
              back
            </button>
            <mat-menu #backMenu="matMenu">
              <button
                *ngFor="
                  let actions of singleLessonService.evalService
                    .lessonPriorSteps
                "
                mat-menu-item
                (click)="confirmSubmitDialog(actions)"
              >
                {{ actions.Description }}
              </button>
            </mat-menu>
              <button
                class="btn btn-primary"
                mat-button
                [matMenuTriggerFor]="submitMenu"
                id="submitButton"
                *ngIf="singleLessonService.evalService.lessonNextSteps.length > 0"
              >
                submit
              </button>
              <mat-menu #submitMenu="matMenu">
                <button
                  *ngFor="
                    let actions of singleLessonService.evalService
                      .lessonNextSteps
                  "
                  mat-menu-item
                  (click)="confirmSubmitDialog(actions)"
                >
                  {{ actions.Description }}
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row loader" *ngIf="!singleLessonService.showLesson">
    <div class="col col-12 text-danger">
      Sorry you are not authorized to view this lesson.
    </div>
  </div>
</div>

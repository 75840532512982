import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-add-approval-trigger-value',
  templateUrl: './add-approval-trigger-value.component.html',
  styleUrl: './add-approval-trigger-value.component.scss'
})
export class AddApprovalTriggerValueComponent implements OnInit{


    code: string = '';
    description: string = '';

    constructor(
      public dialogRef: MatDialogRef<AddApprovalTriggerValueComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      private toastService: ToastService
    ) {}


    ngOnInit(): void {
      if(this.data.operation === 'edit'){
        this.code = this.data.value.code;
        this.description = this.data.value.description;
      }
    }

    cancel() {
      this.dialogRef.close({update: false, value: null});
    }

    submit() {
      if(this.code === '' || this.description === ''){
        this.toastService.showError(true, 'Oops! Looks like you left one of the required fields empty');
      }
      else{
        this.dialogRef.close({update: true, code: this.code, description: this.description });
      }

    }

}

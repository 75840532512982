import { Component, Input } from '@angular/core';
import { DisciplineService } from 'src/app/core/http/discipline.service';
import { AssetService } from 'src/app/core/http/asset.service';
import { CopService } from 'src/app/core/http/cop.service';
import { RegionService } from 'src/app/core/http/region.service';
import { DepartmentService } from 'src/app/core/http/department.service';
import { RelatedProcessService } from 'src/app/core/http/related-process.service';
import { TeamService } from 'src/app/core/http/team.service';
import { ToastService } from "src/app/core/services/toast.service";
import { AddApprovalTriggerValueComponent } from '../../../dialogs/add-approval-trigger-value/add-approval-trigger-value.component';
import { MatDialog } from '@angular/material/dialog';
import { Dialog } from '@angular/cdk/dialog';


@Component({
  selector: 'app-approval-trigger-table',
  templateUrl: './approval-trigger-table.component.html',
  styleUrl: './approval-trigger-table.component.scss'
})
export class ApprovalTriggerTableComponent {

  dataSource : any;
  approvalTrigger: any;

  constructor(
    private disciplineService: DisciplineService,
    private assetService: AssetService,
    private copService: CopService,
    private regionService: RegionService,
    private departmentService: DepartmentService,
    private relatedProcessService: RelatedProcessService,
    private teamService: TeamService,
    private toastService: ToastService,
    private dialog: MatDialog
  ){}
  
  
  @Input() set data(data: any) {
    this.dataSource = data;
  }

  @Input() set trigger(trigger: any) {
    this.approvalTrigger = trigger;
  }

  // Revisit
  // editItem(item: any){
  //   const dialogRef = this.dialog.open(AddApprovalTriggerValueComponent, {
  //     width: "500px",
  //     data: { value: item, approvalTriggerType: this.approvalTrigger, operation: 'edit'}
  //   });
  //   dialogRef.afterClosed().subscribe((result) => { 
  //     console.log('result edit : ', result);
  //   })
  // }

  deleteItem(item: any){
    switch(this.approvalTrigger){
      case "Discipline":
        this.disciplineService.deleteDiscipline(item.code).subscribe(res => {
          if(res === null){
            this.disciplineService.getAllDisciplines().subscribe(res => {
              this.dataSource = res;
            })
            this.toastService.showSuccess(true, 'Removed Discipline')
          }
        })
        break;
      case "Asset":
        this.assetService.deleteAsset(item.code).subscribe(res => {
          if(res === null){
            this.assetService.getAllAssets().subscribe(res => {
              this.dataSource = res;
            })
            this.toastService.showSuccess(true, 'Removed Asset')
          }
        })
        break;
      case "CoP":
        this.copService.deleteCop(item.code).subscribe(res => {
          if(res === null){
            this.copService.getAllCops().subscribe(res => {
              this.dataSource = res;
            })
            this.toastService.showSuccess(true, 'Removed CoP')
          }
        })
        break;
      case "Region":
        this.regionService.deleteRegion(item.code).subscribe(res => {
          if(res === null){
            this.regionService.getAllRegions().subscribe(res => {
              this.dataSource = res;
            })
            this.toastService.showSuccess(true, 'Removed Region')
          }
        })
        break;
      case "Team":
        this.teamService.deleteTeam(item.code).subscribe(res => {
          if(res === null){
            this.teamService.getAllTeams().subscribe(res => {
              this.dataSource = res;
            })
          }
          this.toastService.showSuccess(true, 'Removed Team')
        })
        break;
      case "Department":
        this.departmentService.deleteDepartment(item.code).subscribe(res => {
          if(res === null){
            this.departmentService.getAllDepartments().subscribe(res => {
              this.dataSource = res;
            })
            this.toastService.showSuccess(true, 'Removed Department')
          }
        })
        break;
      case "Related Process":
        this.relatedProcessService.deleteRelatedProcess(item.code).subscribe(res => {
          if(res === null){
            this.relatedProcessService.getAllRelatedProcesses().subscribe(res => {
              this.dataSource = res;
            })
            this.toastService.showSuccess(true, 'Removed Process')
          }
        })
        break;
      default:
        break;
    }
  }



  async addApprovalTriggerValue(){
    const dialogRef = this.dialog.open(AddApprovalTriggerValueComponent, {
      width: "500px",
      data: { approvalTriggerType: this.approvalTrigger, operation: 'add'}
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result.update){
        switch(this.approvalTrigger){
          case "Discipline":
            this.createAndFetchDisciplines(result.code, result.description);
            break;
          case "Asset":
            this.createAndFetchAssets(result.code, result.description);
            break;
          case "CoP":
            this.createAndFetchCops(result.code, result.description);
            break;
          case "Region":
            this.createAndFetchRegions(result.code, result.description);
            break;
          case "Team":
            this.createAndFetchTeams(result.code, result.description);
            break;
          case "Department":
            this.createAndFetchDepartments(result.code, result.description);
            break;
          case "Related Process":
            this.createAndFetchProcesses(result.code, result.description);
          default:
            break;
        }
      }
    });
  }

  createAndFetchDisciplines(code, description){
    this.disciplineService.createDiscipline(code, description).subscribe(res => {
      if(res.code && res.description){
        this.disciplineService.getAllDisciplines().subscribe(res => {
          if(res){
            this.dataSource = res;
            this.toastService.showSuccess(true, 'Successfully added new Discipline');
          }
        });
      }
      else{
        this.toastService.showError(true, 'Unable to add new Discipline')
      }
    })
  }

  createAndFetchAssets(code, description){
    this.assetService.createAsset(code, description).subscribe(res => {
      if(res){
        this.assetService.getAllAssets().subscribe(res => {
          if(res){
            this.dataSource = res;
            this.toastService.showSuccess(true, 'Successfully added new Asset');
          }
        });
      }
      else{
        this.toastService.showError(true, 'Unable to add new Asset')
      }
    })

  }

  createAndFetchRegions(code, description){
    this.regionService.createRegion(code, description).subscribe(res => {
      if(res){
        this.regionService.getAllRegions().subscribe(res => {
          if(res){
            this.dataSource = res;
            this.toastService.showSuccess(true, 'Successfully added new Region');
          }
        });
      }
      else{
        this.toastService.showError(true, 'Unable to add new Region')
      }
    })

  }

  createAndFetchProcesses(code, description){
    this.relatedProcessService.createRelatedProcess(code, description).subscribe(res => {
      if(res){
        this.relatedProcessService.getAllRelatedProcesses().subscribe(res => {
          if(res){
            this.dataSource = res;
            this.toastService.showSuccess(true, 'Successfully added new Related Process');
          }
        });
      }
      else{
        this.toastService.showError(true, 'Unable to add new Related Process')
      }
    })

  }

  createAndFetchTeams(code, description){
    this.teamService.createTeam(code, description).subscribe(res => {
      if(res){
        this.teamService.getAllTeams().subscribe(res => {
          if(res){
            this.dataSource = res;
            this.toastService.showSuccess(true, 'Successfully added new Team');
          }
        });
      }
      else{
        this.toastService.showError(true, 'Unable to add new Team')
      }
    })

  }

  createAndFetchDepartments(code, description){
    this.departmentService.createDepartment(code, description).subscribe(res => {
      if(res){
        this.departmentService.getAllDepartments().subscribe(res => {
          if(res){
            this.dataSource = res;
            this.toastService.showSuccess(true, 'Successfully added new Department');
          }
        });
      }
      else{
        this.toastService.showError(true, 'Unable to add new Department')
      }
    })

  }

  createAndFetchCops(code, description){
    this.copService.createCop(code, description).subscribe(res => {
      if(res){
        this.copService.getAllCops().subscribe(res => {
          if(res){
            this.dataSource = res;
            this.toastService.showSuccess(true, 'Successfully added new CoP');
          }
        });
      }
      else{
        this.toastService.showError(true, 'Unable to add new CoP')
      }
    })
  }
}


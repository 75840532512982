<div class="container-fluid p-4">
  <h2> Code </h2>
  <mat-form-field appearance="outline" class="triggerValue">
    <input matInput [(ngModel)]="code" name="code" placeholder="Please enter a valid code">
  </mat-form-field>

  <h2> Description </h2>
  <mat-form-field appearance="outline" class="triggerValue">
    <input matInput [(ngModel)]="description" name="description" placeholder="Please enter a valid description">
  </mat-form-field>

  <div class="d-flex flex-row justify-content-end mt-4" style="gap: 1rem">
    <button
      type="button"
      mat-button
      class="btn"
      (click)="cancel()"
      id="cancelButton"
    >
      cancel
    </button>
    <button class="btn btn-primary" id="submitButton" (click)="submit()">
      submit
    </button>
  </div>
  
</div>
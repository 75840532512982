<app-page-header [pageTitle]="pageTitle"></app-page-header>
<div class="selectionContainer">
  <div class="approvalTriggerContainer" >
    <h3>First, please select the approval trigger you want to view and/or configure</h3>
    <mat-form-field appearance="outline" class="approvalTriggerDropdown">
      <mat-select [(value)]="approvalTriggerSelected" (selectionChange)="approvalTriggerSelectedChange($event.value)" placeholder="Select Approval Trigger">
        <mat-option *ngFor="let approvalTrigger of approvalTriggers" [value]="approvalTrigger">
          {{ approvalTrigger }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<app-approval-trigger-table [data]="approvalTriggerValues" [trigger]="approvalTriggerSelected" *ngIf="showTable"></app-approval-trigger-table>
<button class="btn add" style="margin-top: 1rem;" (click)="addApprovalTriggerValue()">+</button>
<table>
  <thead>
    <tr>
      <th>Code</th>
      <th>Description</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of dataSource">
      <tr>
        <td>{{ item.code }}</td>
        <td>{{ item.description }}</td>
        <td>
          <mat-icon class="delete-icon" (click)="deleteItem(item)" matTooltip="delete">delete</mat-icon>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
